import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './scss/index.scss';

import Container from './Container';
import { AuthProvider } from './AuthContext';
import { CompetitionProvider } from './CompetitionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename="/schuelerwettbewerb/">
    <AuthProvider>
      <CompetitionProvider>
        <Container />
      </CompetitionProvider>
    </AuthProvider>
  </BrowserRouter>
);