import React, { useEffect, useState, useRef } from 'react';
import { useAuthContext, useAuthCheck } from '../AuthContext';
import { useCompetitionContext } from '../CompetitionContext';
import { useNavigate } from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import LeftNav from './LeftNav';
import RightNav from './RightNav';

import Loading from './Loading';
import VideoPlayer from './VideoPlayer';
import Timeline from './Timeline';

import '../scss/Profile.scss'

function Profile() {

    const navigate = useNavigate();

    const checkJWT = useAuthCheck().checkJWT;
    const getCookie = useAuthCheck().getCookie;
    const loading = useAuthContext().loading;
    const root = useAuthContext().root;
    const user = useAuthContext().user;
    const userRef = useRef(useAuthContext().user);
    const updateUser = useAuthCheck().updateUser;

    const { currentCompIndex, competitionCats } = useCompetitionContext();

    const [edit, setEdit] = useState(false);
    const [loadingPost, setLoadingPost] = useState(true);

    const [profileInfo, setProfileInfo] = useState({
        profilefoto: false
    });

    const [profileContent, setProfileContent] = useState({
        research: {
            content: '',
            pdf: null,
            media_type: 'slideshow',
            slideshow: null
        },
        design: {
            content: '',
            pdf: null,
            media_type: 'slideshow',
            slideshow: null
        },
        share: {
            content: '',
            pdf: null,
            media_type: 'slideshow',
            slideshow: null
        },
    });

    const [updated, setUpdated] = useState(false);

    //////////////////////////////////////////////////////////////
    /////// CHECK IF USER HAS ACCESS TO THIS COMPETITION /////////
    //////////////////////////////////////////////////////////////

    useEffect(() => {
        
        if (user.compSlug === '' || !user.compSlug) return

        if (competitionCats.sub[currentCompIndex].slug !== user.compSlug) { 
            document.cookie = `token=; path=/;`;
            navigate('/', { replace: true });
            return
        }
        
    }, [competitionCats, currentCompIndex, user, navigate])


    ///////////////////////////////////
    /////// UTILITY FUNCITONS /////////
    ///////////////////////////////////

    async function uploadFile(oldID, file, postObj, key) {

        let newID;
        let newURL;

        function buildPostObj(obj, key, id) {

            let newObj = obj;

            function objWalker (obj, key, id) {
                Object.keys(obj).forEach(k => {
                    k === key
                    ? obj[k] = id
                    : objWalker(obj[k], key, id)
                })
            }

            objWalker(newObj, key, id);
            return newObj;
            
        }

        try {

            const res = await fetch(root + '/wp/v2/media', {
                method: "POST",
                headers: {
                    authorization: "Bearer " + getCookie('token')
                },
                body: file
            })

            const json = await res.json();

            newID = json.id;
            newURL = json.source_url;

            if (key === 'pdf') {
                switch (Object.keys(postObj.acf)[0]) {
                    case 'research':
                    case 'design':
                    case 'share':
                        const key = Object.keys(postObj.acf)[0];
                        setProfileContent(prev => {
                            return (
                                {
                                    ...prev, [key] : {
                                        ...prev[key],
                                        pdf: {
                                            url: newURL,
                                            id: newID
                                        }
                                    }
                                }
                            )
                        })
                        break;
                    case 'profile_info':
                        setProfileInfo(prev => {
                            return (
                                {
                                    ...prev, pdf: {
                                        url: newURL,
                                        id: newID
                                    }
                                }
                            )
                        })
                        break;
                    default:
                        setProfileInfo(prev => {
                            return (
                                {
                                    ...prev, profilefoto: {
                                        url: newURL,
                                        id: newID
                                    }
                                }
                            )
                        })
                    break;
                }  

            }

            if (key === 'profilefoto') {

                setProfileInfo(prev => {
                    return (
                        {
                            ...prev, profilefoto: {
                                url: newURL,
                                id: newID
                            }
                        }
                    )
                })

            }

            if (key === 'slideshow') {

                const key = Object.keys(postObj.acf)[0];

                let arr = profileContent[key].slideshow;
                const convertedOldID = parseInt(oldID)
                

                arr.forEach((el, index) => {
                    if (el.image.id === convertedOldID) {
                        arr[index].image.id = newID;
                        arr[index].image.url = newURL
                    }
                })

                setProfileContent(prev => {
                    return (
                        {...prev, [key] : {
                            ...prev[key] ,slideshow: arr
                        }}
                    )
                })

            }

            
        } catch(e) {
            console.log(e)
            navigate('/error', {replace: true});
        }

        try {

            let payload;

            if (key === 'slideshow') {

                const key = Object.keys(postObj.acf)[0];

                let obj = profileContent[key].slideshow

                let IDs = obj.map(el => { return el.image.id });

                let arr = IDs.map(el => { return { image: el } });

                payload = JSON.stringify({
                    acf: {
                        [key]: {
                            slideshow: arr
                        }
                    }
                })
            }

            if (key === 'pdf' || key === 'profilefoto') {
                payload = JSON.stringify(buildPostObj(postObj, key, newID));
            }

            const res = await fetch(root + '/wp/v2/posts/' + user.postId, {
                method: 'POST',
                headers: {
                  authorization: "Bearer " + getCookie('token'),
                  "Content-Type":"application/json"
                },
                body: payload
            })

            if (res.status === 200) {
                deleteFile(oldID)
            }

        } catch(e) {
            console.log(e)
            navigate('/error', {replace: true});
        }
    }

    async function deleteACFContent(e) {
        const target = e.target;

        const key = target.dataset.key;
        const ID = target.dataset.id;
        const section = target.dataset.section;

        let payload;

        if (key !== 'pdf') {

            let obj = profileContent[section].slideshow;
    
            obj.splice(key, 1);
    
            setProfileContent(prev => {
                return (
                    {...prev, [section] : {
                        ...prev[section] ,slideshow: obj
                    }}
                )
            })
    
            let IDs = obj.map(el => {return el.image.id});
            
            let arr = IDs.map(el => { return {image: el} });
            
            payload = JSON.stringify({ acf: {
                [section]: {
                    slideshow: arr
                    }
                }
            })

        } else {
            
            setProfileContent(prev => {
                return (
                    {...prev, [section]: {
                        ...prev[section], pdf: false
                        }
                    }
                )
            })

            payload = JSON.stringify({ acf: {
                [section]: {
                    pdf: '242423842394287834535358878404033938758303939473948927423984'
                    }
                }
            })

        }

        const res = await fetch(root + '/wp/v2/posts/' + user.postId, {
            method: "POST",
            headers: {
                authorization: "Bearer " + getCookie('token'),
                "Content-Type":"application/json"
            },
            body: payload
        })

        if (res.status === 200) {
            deleteFile(ID);
        }
    }

    async function addACFContent(e) {
        
        const target = e.target;
        const section = target.dataset.section;
        const file = target.files[0];

        let newID;
        let newURL;

        let payload = new FormData();
        payload.append("file", file);

        try {

            const res = await fetch(root + '/wp/v2/media', {
                method: "POST",
                headers: {
                    authorization: "Bearer " + getCookie('token')
                },
                body: payload
            })

            const json = await res.json();

            newID = json.id;
            newURL = json.source_url;

            let obj = profileContent[section].slideshow;

            if (obj === false) {
                obj = []
            } 
            
            obj.push({
                image : {
                    id: newID,
                    url: newURL
                }
            })

            setProfileContent(prev => {
                return (
                    {...prev, [section] : {
                        ...prev[section] , slideshow: obj
                    }}
                )
            })
            

        } catch(e) {
            console.log(e);
            navigate('/error');
        }



        try {

            let obj = profileContent[section].slideshow;

            if (obj === false) {
                obj = []
                obj.push({
                    image : {
                        id: newID,
                        url: newURL
                    }
                })
            }

            let IDs = obj.map(el => {return el.image.id});

        
            let arr = IDs.map(el => { return {image: el} });
            
            const payload = JSON.stringify({ acf: {
                [section]: {
                    slideshow: arr
                    }
                }
            })

            fetch(root + '/wp/v2/posts/' + user.postId, {
                method: "POST",
                headers: {
                    authorization: "Bearer " + getCookie('token'),
                    "Content-Type":"application/json"
                },
                body: payload
            })


        } catch(e) {
            console.log(e);
            navigate('/error');
        }
    }

    async function replaceACFContent(e) {

        const section = e.target.dataset.section
        const deleteID = e.target.dataset.id

        const file = e.target.files[0];

        let payload = new FormData();
        payload.append("file", file);

        const postObj = { acf: {
                [section]: {
                    slideshow: undefined
                }
            } 
        }

        uploadFile(deleteID, payload, postObj, 'slideshow');
        
    }

    async function deleteFile(ID) {

        if (ID === undefined) return

        try {

            fetch(root + '/wp/v2/media/' + ID, {
                method: "DELETE",
                headers: {
                    authorization: "Bearer " + getCookie('token'),
                    "Content-Type":"application/json"
                }
            })

        } catch(e) {
            console.log(e)
            navigate('/error', {replace: true});
        }

    }

    useEffect(() => {

        async function getProfilePost() {
            
            try {

                const res = await fetch(root + '/wp/v2/posts?author=' + user.id + '&acf_format=standard')

                const json = await res.json();

                setProfileInfo(json[0].acf.profile_info);

                setProfileContent({
                    research: json[0].acf.research,
                    design: json[0].acf.design,
                    share: json[0].acf.share
                });
                
                updateUser({...user, postId: json[0].id});

                setLoadingPost(false)
    
            } catch(e) {
                console.log(e)
            
                navigate('/error', {replace: true});
            }
        }

        if (userRef.current !== user) return

        getProfilePost();

    }, [navigate, root, user, updateUser])

    useEffect(() => {

        const confirmReload = ((e) => {
            e.preventDefault();
            e.returnValue = '';
        })

        if (updated) {
            window.addEventListener('beforeunload', confirmReload);

            return () => {
                window.removeEventListener('beforeunload', confirmReload);
            }
        }

    }, [updated])

    function handleLogout() {
        document.cookie = `token=; path=/;`;
        checkJWT(`${competitionCats.sub[currentCompIndex].slug}`);
    }

    function redirectInfo() {
        window.open(competitionCats.sub[currentCompIndex].acf.info_link, '__blank');
    }

    function handleEditBtn() {
        setEdit(!edit);
    }

    function handleSaveBtn() {
        
        if (updated) {

            async function updateProfile() {

                try {

                    let obj = {
                        acf: {
                            profile_info: {},
                            research: {},
                            design: {},
                            share: {}
                        }
                    };

                    Object.keys(profileInfo).forEach(key => {
                        obj.acf.profile_info[key] = profileInfo[key]

                        obj.acf.profile_info.profilephoto === false
                        ? obj.acf.profile_info.profilefoto = null
                        : obj.acf.profile_info.profilefoto = profileInfo.profilefoto.ID

                        obj.acf.profile_info.pdf === false
                        ? obj.acf.profile_info.pdf = null
                        : obj.acf.profile_info.pdf = profileInfo.pdf.ID
                    })

                    Object.keys(profileContent).forEach(section => {
                        Object.keys(profileContent[section]).forEach(key => {
                            
                            if (key === 'slideshow' || key === 'pdf') {
                                profileContent[section][key] === false
                                ? obj.acf[section][key] = null
                                : obj.acf[section][key] = profileContent[section][key].id
                            } else {
                                obj.acf[section][key] = profileContent[section][key];
                            }

                        })
                    })

                    const payload = JSON.stringify(obj);

                    fetch(root + '/wp/v2/posts/' + user.postId, {
                        method: 'POST',
                        headers: {
                          authorization: "Bearer " + getCookie('token'),
                          "Content-Type":"application/json"
                        },
                        body: payload
                    })

                } catch(e) {
                    console.log(e)
            
                    navigate('/error', {replace: true});
                }

            }

            updateProfile();
        }

        setEdit(!edit);
        setUpdated(false);

    }

    function handleInput(e) {

        if (!updated) {
            setUpdated(true)
        };
        
        const key = e.target.name;

        setProfileInfo(prev => {
            return (
                {...prev, [key]: e.target.value}
            )
        })
    }

    function handleContentInput(e) {

        if (!updated) {
            setUpdated(true)
        };

        const name = e.target.name;
        const section = name.split('.')[0];
        const key = name.split('.')[1];
        
        setProfileContent(prev => {
            return (
                {...prev, [section] : {
                    ...prev[section], [key]: e.target.value
                }}
            )
        })
    }

    function handleProfilePicUpload(e) {
        
        const deleteID = profileInfo.profilefoto.ID

        const file = e.target.files[0];

        let payload = new FormData();
        payload.append("file", file);

        const postObj = { acf : {
                profile_info: {
                    profilefoto: undefined
                }
            }
        }
        
        uploadFile(deleteID, payload, postObj, 'profilefoto');
    }

    function viewPDF(e) {
        window.open(e.target.value, '_blank').focus();
    }

    function uploadPDF(e) {

        const section = e.target.name
        
        const deleteID = profileContent[section].pdf.id
        
        const file = e.target.files[0];

        let payload = new FormData();
        payload.append("file", file);

        const postObj = { acf: {
                [section]: {
                    pdf: undefined
                }
            } 
        }

        uploadFile(deleteID, payload, postObj, 'pdf')

    }

    function handleVideoLink(e) {

        if (!updated) {
            setUpdated(true)
        };

        const section = e.target.dataset.section
        const content = e.target.value

        setProfileContent(prev => {
            return({
                ...prev, [section] : {
                    ...prev[section],
                    video_url: content
                }
            })
        })
        
    }

    function mapImgUrls(arr) {

        let items = [];

        if (arr === null) return

        arr.forEach(el => {
            items.push({
                original: el.image.url 
            })
        })

        return items;

    }

    function setMediaType(e) {

        if (!updated) {setUpdated(true)};

        const mediaType = e.target.value;
        const section  = e.target.dataset.gallery

        setProfileContent(prev => {
            return (
                {...prev, [section] : {
                    ...prev[section], media_type: mediaType
                }}
            )
        })
    }


    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <>

                {loadingPost
                    ? <Loading />
                    : <>
                        <div className="mainsection">

                            <section className="subsection profile">

                                <div className="profile-toolbar">

                                    {edit
                                        ? <button type="button" className="profile-toolbar__btn btn-save" onClick={handleSaveBtn}>Speichern &amp; Ansehen</button>
                                        : <button type="button" className="profile-toolbar__btn btn-edit" onClick={handleEditBtn}>Profil bearbeiten</button>
                                    }

                                    <button type="button" className="profile-toolbar__btn btn-info" onClick={redirectInfo}></button>
                                    <button type="button" className="profile-toolbar__btn btn-logout" onClick={handleLogout}></button>

                                    {edit &&
                                        <div className="profile-toolbar__tips">

                                            <div className="profile-toolbar__tips-title">
                                                <p>Hinweise zum Bearbeiten des <br></br> Profils</p>
                                            </div>

                                            <div className="profile-toolbar__tips-column">
                                                
                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/icon_edit.svg`} style={{width: "140%"}} alt="Edit Icon"></img>
                                                    </div>
                                                    <p>Text bearbeiten</p>
                                                </div>

                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/Upload.svg`} alt="Upload Icon"></img>
                                                    </div>
                                                    <p>Datei hochladen</p>
                                                </div>

                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/ic_papierkorb.svg`} alt="Papierkorb Icon"></img>
                                                    </div>
                                                    <p>Papierkorb</p>
                                                </div>

                                            </div>

                                            <div className="profile-toolbar__tips-column">

                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/camera.svg`} alt="Camera Icon"></img>
                                                    </div>
                                                    <p>Bild hochladen</p>
                                                </div>

                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/video.svg`} alt="Video Icon"></img>
                                                    </div>
                                                    <p>Video hochladen</p>
                                                </div>

                                                <div className="profile-toolbar__tips-column-row">
                                                    <div>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/ic_plus_white.svg`} alt="Plus Icon"></img>
                                                    </div>
                                                    <p>Weitere Bilder hochladen</p>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    }

                                </div>

                                <div className="profile-name">
                                    {edit
                                        ? <div className="edit-overlay"><input autoComplete="off" maxLength="50" value={profileInfo.team_name} name="team_name" onChange={handleInput}></input></div>
                                        : <h3>{profileInfo.team_name}</h3>
                                    }

                                    {edit
                                        ? <div className="edit-overlay"><input autoComplete="off" maxLength="50" value={profileInfo.project_name} name="project_name" onChange={handleInput}></input></div>
                                        : <h1>{profileInfo.project_name}</h1>
                                    }
                                </div>

                                <div className="profile-info">
                                    <div className="profile-info-txt">

                                        <div>
                                            <p>Name der Schule:</p>
                                            {edit
                                                ? <div className="edit-overlay"><input autoComplete="off" maxLength="50" value={profileInfo.school_name} name="school_name" onChange={handleInput}></input></div>
                                                : <p>{profileInfo.school_name}</p>
                                            }
                                        </div>
                                        <div>
                                            <p>Klassenstufe:</p>
                                            {edit
                                                ? <div className="edit-overlay"><input autoComplete="off" maxLength="20" value={profileInfo.class_grade} name="class_grade" onChange={handleInput}></input></div>
                                                : <p>{profileInfo.class_grade}</p>
                                            }
                                        </div>
                                        <div>
                                            <p>Anzahl Schüler*innen:</p>
                                            {edit
                                                ? <div className="edit-overlay"><input autoComplete="off" maxLength="20" value={profileInfo.number_of_participants} name="number_of_participants" onChange={handleInput}></input></div>
                                                : <p>{profileInfo.number_of_participants}</p>
                                            }
                                        </div>
                                        <div>
                                            <p>Fach/Thema:</p>
                                            {edit
                                                ? <div className="edit-overlay"><input autoComplete="off" maxLength="50" value={profileInfo.topic} name="topic" onChange={handleInput}></input></div>
                                                : <p>{profileInfo.topic}</p>
                                            }
                                        </div>
                                        <div>
                                            <p>Über uns:</p>
                                            {edit
                                                ?   <div className="edit-overlay">
                                                        <textarea autoComplete="off" value={profileInfo.about_us} name="about_us" onChange={handleInput} maxLength="440"></textarea>
                                                        <p className="tooltip">Max. 440 Zeichen mit Leerzeichen</p>
                                                    </div>
                                                :   <p>{profileInfo.about_us}</p>
                                            }

                                        </div>

                                    </div>
                                    <div className="profile-info-img">
                                        <img
                                            src={
                                                profileInfo.profilefoto !== false
                                                    ? profileInfo.profilefoto.url
                                                    : `${process.env.PUBLIC_URL}/assets/platzhalter_610x345.png`
                                            }
                                            alt="Profile Pic">
                                        </img>
                                        {edit &&
                                            <>
                                                <label htmlFor="img-upload" className="btn-img-upload"></label>
                                                <input name="profilefoto" id="img-upload" type="file" accept=".jpeg, .png" onChange={handleProfilePicUpload}></input>
                                            </>
                                        }
                                    </div>
                                </div>

                            </section>

                            <section className="subsection research">

                                <div className="subsection-img-container" data-gallery="research">

                                    {edit &&
                                        <p className="tooltip">Bitte lade max. 3 Bilder (JPG, PNG) ODER ein Video hoch</p>
                                    }

                                    {
                                        profileContent.research.media_type === 'slideshow'
                                            ?
                                            <>
                                                {(profileContent.research.slideshow !== false && profileContent.research.slideshow.length !== 0)
                                                    ?
                                                    <ImageGallery
                                                        additionalClass="subsection-img"
                                                        showFullscreenButton={false}
                                                        showPlayButton={false}
                                                        showBullets={true}
                                                        renderLeftNav={(onClick, disabled) => (
                                                            <LeftNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        renderRightNav={(onClick, disabled) => (
                                                            <RightNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        items={mapImgUrls(profileContent.research.slideshow)}
                                                    />
                                                    :
                                                    <div className="subsection-img">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/platzhalter_540x340.png`} alt="Share Pic"></img>
                                                    </div>
                                                }

                                                {edit &&
                                                 <>
                                                    <button className="subsection-img-btn-slideshow selected" type="button" value="slideshow" data-gallery="research" onClick={setMediaType}></button>
                                                    <button className="subsection-img-btn-video" type="button" value="video" data-gallery="research" onClick={setMediaType}></button>
                                                 </>
                                                }

                                                {edit &&

                                                    <div className="subsection__thumbnails">
                                                        {profileContent.research.slideshow !== false &&

                                                            profileContent.research.slideshow.map((img, index) => {
                                                                return (
                                                                    <div className="subsection__thumbnails-img" key={img.image.id}>
                                                                        <img src={img.image.url} alt={img.image.title}></img>

                                                                        <button
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="research"
                                                                            type="button"
                                                                            className="subsection__thumbnails-img-del"
                                                                            onClick={deleteACFContent}>
                                                                        </button>

                                                                        <input
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="research"
                                                                            type="file"
                                                                            accept=".jpeg, .png"
                                                                            id={'research-replace-img-' + img.image.id}
                                                                            onChange={replaceACFContent}></input>
                                                                        <label htmlFor={'research-replace-img-' + img.image.id} className="subsection__thumbnails-img-replace"></label>

                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                        {
                                                            (profileContent.research.slideshow.length < 3 || profileContent.research.slideshow === false) &&
                                                            <div className="subsection__thumbnails-new">
                                                                <input type="file" accept=".jpeg, .png" id="img-upload-research" onChange={addACFContent} data-section="research"></input>
                                                                <label className="subsection__thumbnails-new-add" htmlFor="img-upload-research"></label>
                                                            </div>
                                                        }
                                                    </div>

                                                }
                                            </>
                                            : <VideoPlayer
                                                edit={edit}
                                                setMediaType={setMediaType}
                                                handleVideoLink={handleVideoLink}
                                                profileContent={profileContent}
                                                gallery="research"
                                            />

                                    }

                                </div>

                                <img className="research-ilu" src={`${process.env.PUBLIC_URL}/assets/Forschen_illu.svg`} alt="Research Ilustration"></img>

                                <div className="subsection-txt">

                                    <img className="subsection-txt-num" src={`${process.env.PUBLIC_URL}/assets/One.svg`} alt="One"></img>

                                    <h2>Forschen</h2>

                                    {edit
                                        ?   <div className="edit-overlay">
                                                <textarea autoComplete="off" value={profileContent.research.content} name="research.content" onChange={handleContentInput} maxLength="440"></textarea>
                                                <p className="tooltip">Max. 440 Zeichen mit Leerzeichen</p>
                                            </div>
                                        :   <p>{profileContent.research.content}</p>
                                    }

                                    {edit
                                        ? 
                                        <div className="subsection-txt-btns">
                                            <label htmlFor="pdf-upload-research" className="btn-pdf--upload">{profileContent.research.pdf !== false ? 'PDF Ersetzen' : 'PDF Hochladen'}</label>
                                            <input id="pdf-upload-research" type="file" accept='.pdf' name="research" onChange={uploadPDF}></input>
                                            <button type="button" className="btn-del" data-key="pdf" data-section="research" data-id={profileContent.research.pdf.id} onClick={deleteACFContent}></button>
                                            <p className="tooltip"></p>
                                        </div>
                                        :   <button className={profileContent.research.pdf !== false ? 'btn-pdf' : 'hidden'} type="button" value={profileContent.research.pdf.url} onClick={viewPDF}>PDF downloaden</button>
                                    }

                                </div>

                            </section>

                            <section className="subsection design">

                                <div className="subsection-img-container" data-gallery="design">

                                    {edit &&
                                        <p className="tooltip">Bitte lade max. 3 Bilder (JPG, PNG) ODER ein Video hoch</p>
                                    }

                                    {
                                        profileContent.design.media_type === 'slideshow'
                                            ?
                                            <>
                                                {(profileContent.design.slideshow !== false && profileContent.design.slideshow.length !== 0)
                                                    ?
                                                    <ImageGallery
                                                        additionalClass="subsection-img"
                                                        showFullscreenButton={false}
                                                        showPlayButton={false}
                                                        showBullets={true}
                                                        renderLeftNav={(onClick, disabled) => (
                                                            <LeftNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        renderRightNav={(onClick, disabled) => (
                                                            <RightNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        items={mapImgUrls(profileContent.design.slideshow)}
                                                    />
                                                    :
                                                    <div className="subsection-img">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/platzhalter_540x340.png`} alt="Share Pic"></img>
                                                    </div>
                                                }

                                                {edit &&
                                                    <>
                                                        <button className="subsection-img-btn-slideshow selected" type="button" value="slideshow" data-gallery="design" onClick={setMediaType}></button>
                                                        <button className="subsection-img-btn-video" type="button" value="video" data-gallery="design" onClick={setMediaType}></button>
                                                    </>
                                                }

                                                {edit &&

                                                    <div className="subsection__thumbnails">
                                                        {profileContent.design.slideshow !== false &&

                                                            profileContent.design.slideshow.map((img, index) => {
                                                                return (
                                                                    <div className="subsection__thumbnails-img" key={img.image.id}>
                                                                        <img src={img.image.url} alt={img.image.title}></img>

                                                                        <button
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="design"
                                                                            type="button"
                                                                            className="subsection__thumbnails-img-del"
                                                                            onClick={deleteACFContent}>
                                                                        </button>

                                                                        <input
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="design"
                                                                            type="file"
                                                                            accept=".jpeg, .png"
                                                                            id={'design-replace-img-' + img.image.id}
                                                                            onChange={replaceACFContent}></input>
                                                                        <label htmlFor={'design-replace-img-' + img.image.id} className="subsection__thumbnails-img-replace"></label>

                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                        {
                                                            (profileContent.design.slideshow.length < 3 || profileContent.design.slideshow === false) &&
                                                            <div className="subsection__thumbnails-new">
                                                                <input type="file" accept=".jpeg, .png" id="img-upload-design" onChange={addACFContent} data-section="design"></input>
                                                                <label className="subsection__thumbnails-new-add" htmlFor="img-upload-design"></label>
                                                            </div>
                                                        }
                                                    </div>

                                                }
                                            </>
                                            : <VideoPlayer
                                                edit={edit}
                                                setMediaType={setMediaType}
                                                handleVideoLink={handleVideoLink}
                                                profileContent={profileContent}
                                                gallery="design"
                                            />

                                    }

                                </div>

                                <img className="design-ilu" src={`${process.env.PUBLIC_URL}/assets/Gestalten_illu.svg`} alt="Design Ilustration"></img>

                                <div className="subsection-txt">

                                    <img className="subsection-txt-num" src={`${process.env.PUBLIC_URL}/assets/Two.svg`} alt="Two"></img>

                                    <h2>Gestalten</h2>
                                    {edit
                                        ?   <div className="edit-overlay">
                                                <textarea autoComplete="off" value={profileContent.design.content} name="design.content" onChange={handleContentInput} maxLength="440"></textarea>
                                                <p className="tooltip">Max. 440 Zeichen mit Leerzeichen</p>
                                            </div>
                                        :   <p>{profileContent.design.content}</p>
                                    }

                                    {edit
                                        ? 
                                        <div className="subsection-txt-btns">
                                            <label htmlFor="pdf-upload-design" className="btn-pdf--upload">{profileContent.design.pdf !== false ? 'PDF Ersetzen' : 'PDF Hochladen'}</label>
                                            <input id="pdf-upload-design" type="file" accept='.pdf' name="design" onChange={uploadPDF}></input>
                                            <button type="button" className="btn-del" data-key="pdf" data-section="design" data-id={profileContent.design.pdf.id} onClick={deleteACFContent}></button>
                                            <p className="tooltip"></p>
                                        </div>
                                        :   <button className={profileContent.design.pdf !== false ? 'btn-pdf' : 'hidden'} type="button" value={profileContent.design.pdf.url} onClick={viewPDF}>PDF downloaden</button>
                                    }

                                </div>

                            </section>

                            <section className="subsection share">

                                <div className="subsection-img-container" data-gallery="share">

                                    {edit &&
                                        <p className="tooltip">Bitte lade max. 3 Bilder (JPG, PNG) ODER ein Video hoch</p>
                                    }

                                    {
                                        profileContent.share.media_type === 'slideshow'
                                            ?
                                            <>
                                                {(profileContent.share.slideshow !== false && profileContent.share.slideshow.length !== 0)
                                                    ?
                                                    <ImageGallery
                                                        additionalClass="subsection-img"
                                                        showFullscreenButton={false}
                                                        showPlayButton={false}
                                                        showBullets={true}
                                                        renderLeftNav={(onClick, disabled) => (
                                                            <LeftNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        renderRightNav={(onClick, disabled) => (
                                                            <RightNav onClick={onClick} disabled={disabled} />
                                                        )}
                                                        items={mapImgUrls(profileContent.share.slideshow)}
                                                    />
                                                    :
                                                    <div className="subsection-img">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/platzhalter_540x340.png`} alt="Share Pic"></img>
                                                    </div>
                                                }

                                                {edit &&
                                                    <>
                                                        <button className="subsection-img-btn-slideshow selected" type="button" value="slideshow" data-gallery="share" onClick={setMediaType}></button>
                                                        <button className="subsection-img-btn-video" type="button" value="video" data-gallery="share" onClick={setMediaType}></button>
                                                    </>
                                                }

                                                {edit &&

                                                    <div className="subsection__thumbnails">
                                                        {profileContent.share.slideshow !== false &&

                                                            profileContent.share.slideshow.map((img, index) => {
                                                                return (
                                                                    <div className="subsection__thumbnails-img" key={img.image.id}>
                                                                        <img src={img.image.url} alt={img.image.title}></img>

                                                                        <button
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="share"
                                                                            type="button"
                                                                            className="subsection__thumbnails-img-del"
                                                                            onClick={deleteACFContent}>
                                                                        </button>

                                                                        <input
                                                                            data-id={img.image.id}
                                                                            data-key={index}
                                                                            data-section="share"
                                                                            type="file"
                                                                            accept=".jpeg, .png"
                                                                            id={'share-replace-img-' + img.image.id}
                                                                            onChange={replaceACFContent}></input>
                                                                        <label htmlFor={'share-replace-img-' + img.image.id} className="subsection__thumbnails-img-replace"></label>

                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                        {
                                                            (profileContent.share.slideshow.length < 3 || profileContent.share.slideshow === false) &&
                                                            <div className="subsection__thumbnails-new">
                                                                <input type="file" accept=".jpeg, .png" id="img-upload-share" onChange={addACFContent} data-section="share"></input>
                                                                <label className="subsection__thumbnails-new-add" htmlFor="img-upload-share"></label>
                                                            </div>
                                                        }
                                                    </div>

                                                }
                                            </>
                                            : <VideoPlayer
                                                edit={edit}
                                                setMediaType={setMediaType}
                                                handleVideoLink={handleVideoLink}
                                                profileContent={profileContent}
                                                gallery="share"
                                            />

                                    }

                                </div>

                                <img className="share-ilu" src={`${process.env.PUBLIC_URL}/assets/Teilen_illu Copy.svg`} alt="Share Ilustration"></img>

                                <div className="subsection-txt">

                                    <img className="subsection-txt-num" src={`${process.env.PUBLIC_URL}/assets/Three.svg`} alt="Three"></img>

                                    <h2>Teilen</h2>
                                    {edit
                                        ?   <div className="edit-overlay">
                                                <textarea autoComplete="off" value={profileContent.share.content} name="share.content" onChange={handleContentInput} maxLength="440"></textarea>
                                                <p className="tooltip">Max. 440 Zeichen mit Leerzeichen</p>
                                            </div>
                                        :   <p>{profileContent.share.content}</p>
                                    }

                                    {edit
                                        ? 
                                        <div className="subsection-txt-btns">
                                            <label htmlFor="pdf-upload-share" className="btn-pdf--upload">{profileContent.share.pdf !== false ? 'PDF Ersetzen' : 'PDF Hochladen'}</label>
                                            <input id="pdf-upload-share" type="file" accept='.pdf' name="share" onChange={uploadPDF}></input>
                                            <button type="button" className="btn-del" data-key="pdf" data-section="share" data-id={profileContent.share.pdf.id} onClick={deleteACFContent}></button>
                                            <p className="tooltip"></p>
                                        </div>
                                        :   <button className={profileContent.share.pdf !== false ? 'btn-pdf' : 'hidden'} type="button" value={profileContent.share.pdf.url} onClick={viewPDF}>PDF downloaden</button>
                                    }

                                </div>

                            </section>

                            {edit && 
                                <section className="contact">

                                    <div className="contact-toolbar">
                                        <button type="button" className="btn-save" onClick={handleSaveBtn}>Speichern &amp; Ansehen</button>
                                    </div>

                                    <div className="contact-title">
                                        <p>Beratung</p>
                                        <h3>Ansprechpartnerin:</h3>
                                    </div>

                                    <div className="contact-data">
                                        <div className="contact-data__img">
                                            <img src={`${process.env.PUBLIC_URL}/assets/Claudia.webp`} alt="Claudia">
                                            </img>
                                        </div>
                                        <div className="contact-data__txt">
                                            <p>Forum Brandrauchprävention e.V. Geschäftsstelle</p>
                                            <p>Frau Claudia Groetschel</p>
                                            <span>eMail: </span><a href="mailto: info@120sek.de">info@120sek.de</a>
                                            <br></br>
                                            <span>Tel.: </span><span>030/44 02 01 30</span>
                                            <p><a href={competitionCats.sub[currentCompIndex].acf.info_link} target="__blank">Mehr Informationen...</a></p>
                                        </div>
                                    </div>

                                </section>
                            }

                        </div>

                        <Timeline />
                        
                    </>
                }
            
            </>
        )
    }

}

export default Profile;