import parse from 'html-react-parser';

import { useCompetitionContext } from '../CompetitionContext';

import '../scss/Over.scss'

function Over() {

    const { competitionCats, currentCompIndex } = useCompetitionContext();

    return (

        <div className="over">
            <div className="over-container">
                <div className="over-container__txt">
                    {
                        parse(competitionCats.sub[currentCompIndex].acf.competition_over_text)
                    }
                </div>
                <div className="over-container__logo">
                    <div>
                        <img src={competitionCats.sub[currentCompIndex].acf.competition_welcome_icon.url} alt={competitionCats.sub[currentCompIndex].acf.competition_login_icon.alt} />
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Over;