import React, { useEffect, useState } from 'react';

import '../scss/VideoPlayer.scss';

function VideoPlayer(props) {

    const [videoId, setVideoId] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [streaming, setStreaming] = useState('');
    const [lightBox, setLightBox] = useState(false);

    useEffect(() => {

        const url = props.profileContent[props.gallery].video_url;

        const valid = (url) => {
            try {

                return Boolean(new URL(url))

            } catch(e) {
                return false
            }
        }

        if (valid(url)) {

            if (url.includes('youtu')) {

                url.includes('watch')
                ? setVideoId(url.split('watch?v=')[1].slice(0, 11))
                : setVideoId(url.split('/')[3].slice(0, 11))

                setStreaming('youtube');

            }

            if (url.includes('vimeo')) {
                
                setVideoId(url.split('/')[3].slice(0, 9));

                setStreaming('vimeo');

            }

        }

    },[props])

    useEffect(() => {

        const lightBox = document.querySelector('.lightbox')

        if (lightBox === null) return

        lightBox.addEventListener('click', toggleLightbox);
        document.addEventListener('keyup', toggleLightbox);
        
        return () => {
            lightBox.removeEventListener('click', toggleLightbox);
            document.removeEventListener('keyup', toggleLightbox);
        }

    })

    useEffect(() => {

        async function getThumbnail() {

            if (videoId === '') return
    
            if (streaming === 'youtube') {
                setThumbnail('url(https://i.ytimg.com/vi_webp/' + videoId + '/sddefault.webp)')
            }
    
            if (streaming === 'vimeo') {
                
                try {
    
                    const res = await fetch('https://vimeo.com/api/v2/video/' + videoId + '.json', {
                    });
    
                    const json = await res.json();
    
                    setThumbnail('url(' + json[0].thumbnail_large + ')');
    
                } catch(e) {
                    console.log(e)
                }
    
            }
    
        }

        getThumbnail();

    },[videoId, streaming])


    function getEmbed() {
        if (videoId === '') return

        if (streaming === 'youtube') {
            return 'https://www.youtube.com/embed/' + videoId
        }

        if (streaming === 'vimeo') {
            return 'https://player.vimeo.com/video/' + videoId
        }

    }

    function toggleLightbox(e) {

        if (e.type === 'click') {
            if (lightBox) {
            
                document.querySelector('body').style.overflow = 'initial'
                setLightBox(!lightBox);
                
            } else {
                document.querySelector('body').style.overflow = 'hidden'
                setLightBox(!lightBox);
            }
        } else {
            if (e.code === 'Escape') {
                document.querySelector('body').style.overflow = 'initial'
                setLightBox(!lightBox);
            }
        }


    }

    return(
        <>

            {
                props.profileContent[props.gallery].video_url !== ""
                ?
                    <>
                        {props.edit
                        ?
                            <div className="subsection-video" style={{backgroundImage: thumbnail}}>
                            
                                {props.edit &&
                                    <>
                                        <button className="subsection-video-btn-slideshow" type="button" value="slideshow" data-gallery={props.gallery} onClick={props.setMediaType}></button>
                                        <button className="subsection-video-btn-video selected" type="button" value="video" data-gallery={props.gallery} onClick={props.setMediaType}></button>
                                    </>
                                }
    
                            </div>
                        : 
                            <div className="subsection-video" src={props.profileContent[props.gallery].video_url} style={{backgroundImage: thumbnail}}>
                                <button className="subsection-video-btn-play" type="button" onClick={toggleLightbox}></button>
                            </div>
                        }
                    </>
                :
                    <div className="subsection-video">

                        {props.edit &&
                            <>
                                <button className="subsection-video-btn-slideshow" type="button" value="slideshow" data-gallery={props.gallery} onClick={props.setMediaType}></button>
                                <button className="subsection-video-btn-video selected" type="button" value="video" data-gallery={props.gallery} onClick={props.setMediaType}></button>
                            </>
                        }

                    </div>
            }

            {props.edit &&
                <>
                    <input className="subsection-video-input" type="url" placeholder="Video-Link einfügen" value={props.profileContent[props.gallery].video_url} data-section={props.gallery} onChange={props.handleVideoLink}></input>
                    <span></span>
                </>
            }

            {
                lightBox &&
                <>
                    <div className="iframe">
                        <div className="iframe-wrapper">
                            <span>+</span>
                            <iframe 
                                src={getEmbed()}
                                title={getEmbed()}
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                    <div className="lightbox"></div>
                </>
            }

        </>
    )
}

export default VideoPlayer;