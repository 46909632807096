import React from 'react';

import '../scss/Loading.scss';

function Loading() {
    return (
        <div className="loading">
            <img src={`${process.env.PUBLIC_URL}/assets/icon-spinner.svg`} alt="Spinner..."></img>
        </div>
    )
}

export default Loading;