import React, { useContext, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = React.createContext();
const AuthCheckContext = React.createContext();

export function useAuthContext() {
    return useContext(AuthContext);
}

export function useAuthCheck() {
    return useContext(AuthCheckContext);
}

export function AuthProvider({children}) {

    const root = process.env.REACT_APP_BACKEND_URL + '/wp-json';
    const [authorized, setAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        id: '',
        postId: ''
    })

    const navigate = useNavigate();
    const location = useLocation();

    function getCookie(cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie);
        const cArr = cDecoded.split('; ');
        let res;
        cArr.forEach(val => {
          if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
    }
    

    const checkJWT = useCallback(async (slug) => {

        async function checkFirstTimeLogin() {
        
            try {
    
            const res = await fetch(root +'/wp/v2/users/me?acf_format=standard', {
              headers: {
                authorization: "Bearer " + getCookie('token'),
                "Content-Type":"application/json"
              }
            })
    
            const json = await res.json();

            setUser(prev => {
                return (
                    {...prev, 
                        id: json.id,
                        name: json.name,
                        compSlug: json.acf.competition.slug,
                    }
                )
            })
    
            json.acf.first_time_login
            ? navigate(`/${slug}/welcome`, {replace: true})
            : navigate(`/${slug}/profile`, {replace: true})

            setLoading(false);
    
          } catch(e) {

            console.log(e)

            if (location.pathname !== '/error') {
                navigate('/error', {replace: true});
            }
            
            setLoading(false)
          }
        }


        try {

            const res = await fetch( root + '/jwt-auth/v1/token/validate', {
                method: 'POST',
                headers: {
                authorization: 'Bearer ' + getCookie('token'),
                'Content-Type':'application/json'
                }
            })

            if (res.status !== 200) {
                setAuthorized(false);
                setLoading(false);

                if (location.pathname !== `/${slug}/login`) {
                    document.cookie = `token=; path=/;`;
                    navigate(`/${slug}/login`, {replace: true});
                }

            } else {
                setAuthorized(true);

                checkFirstTimeLogin();
            }

        } catch(e) {
            console.error(e);

            if (location.pathname !== '/error') {
                document.cookie = `token=; path=/;`;
                navigate('/error', {replace: true});
            }

            setLoading(false)
        }
    },[navigate, location.pathname, root]);


    function updateUser(obj) {
        setUser(obj)
    }

    return (
        <AuthContext.Provider value={{authorized, loading, user, root}}>
            <AuthCheckContext.Provider value={{checkJWT, updateUser, getCookie}}>
                {children}
            </AuthCheckContext.Provider>
        </AuthContext.Provider>
    )
}