import React, { useEffect, useState } from 'react';
import { useCompetitionContext } from '../CompetitionContext';

import '../scss/Timeline.scss';

function Timeline() {

    const { currentCompIndex, competitionCats } = useCompetitionContext();

    const [datesOriginal, setDatesOriginal] = useState([]);

    useEffect(() => {
      
        const deadlinesArr = [
            competitionCats.sub[currentCompIndex].acf.registration,
            competitionCats.sub[currentCompIndex].acf.project,
            competitionCats.sub[currentCompIndex].acf.jury,
            competitionCats.sub[currentCompIndex].acf.ceremony,
        ]

        setDatesOriginal(deadlinesArr);
        
    }, [competitionCats, currentCompIndex])


    useEffect(() => {
     
        if (!datesOriginal[0]) return

        const current = new Date()


        let index = Object.entries(datesOriginal).findIndex(el => {
            const [day, month, year] = el[1].split(".");
            const date = new Date(year, month - 1, day);
            return date > current;
        })

        if (index === -1) {
            index = 3;
        }

        const nodes = document.querySelector('.timeline').children;
        nodes[index].classList.add('current-step');

    }, [datesOriginal])


    return (
        <div className="timeline">

            <div className="timeline-container">
                <p>{datesOriginal[0]}</p>
                <p>ANMELDESCHLUSS</p>
            </div>

            <div className="timeline-container">
                <p>{datesOriginal[1]}</p>
                <p>PROJEKT-UPLOAD</p>
            </div>

            <div className="timeline-container">
                <p>{datesOriginal[2]}</p>
                <p>JURY-BERATUNG</p>
            </div>

            <div className="timeline-container">
                <p>{datesOriginal[3]}</p>
                <p>PREISVERLEIHUNG</p>
            </div>

        </div>
    )
}

export default Timeline;