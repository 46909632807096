import React from 'react';
import { Routes, Route, Outlet } from  'react-router-dom';

import { useCompetitionContext } from './CompetitionContext';

import ValidateSlug from './components/ValidateSlug';
import CompetitionSelection from './components/CompetionSelection';
import Login from './components/Login';
import Welcome from './components/Welcome';
import Profile from './components/Profile';
import NotFound from './components/NotFound';
import ServerError from './components/ServerError';
import Over from './components/Over';

import './scss/Layout.scss';

function Container() {

    return (
        <Routes>
            <Route element={<Layout />}>
                
                <Route path="/" element={<CompetitionSelection />} />

                <Route path=":compSlug" element={<ValidateSlug />}>
                    <Route path="over" element={<Over />} />
                    <Route path="login" element={<Login />} />
                    <Route path="welcome" element={<Welcome />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="*" />
                </Route>

                <Route path="error" element={<ServerError />} />
                <Route path="/404/*" element={<NotFound />} />

            </Route>
        </Routes>
    )
}


function Layout() {

    const { competitionCats, currentCompIndex } = useCompetitionContext();

    return (
        <>
            <header>
                {
                    currentCompIndex !== null &&
                    <>
                        <a href={competitionCats.sub[currentCompIndex].acf.header.logo_1_link} className="logo__rauchmelder">
                            <img src={competitionCats.sub[currentCompIndex].acf.header.logo_1.url} alt={competitionCats.sub[currentCompIndex].acf.header.logo_1.alt} />
                        </a>
                        <a href={competitionCats.sub[currentCompIndex].acf.header.logo_2_link} className="logo__brandschutz">
                            <img src={competitionCats.sub[currentCompIndex].acf.header.logo_2.url} alt={competitionCats.sub[currentCompIndex].acf.header.logo_2.alt} />
                        </a>
                    </>
                }
            </header>
                
            <Outlet />
                
            <footer>
                <div className="links">
                    {
                        currentCompIndex !== null &&
                        competitionCats.sub[currentCompIndex].acf.footer.links.map((el, index) => {
                            return <a href={el.link.url} key={index}>{el.link.title} |</a>
                        })
                    }
                </div>
                <div className="logo">
                    {
                        currentCompIndex !== null &&
                        <img src={competitionCats.sub[currentCompIndex].acf.footer.logo.url} alt={competitionCats.sub[currentCompIndex].acf.footer.logo.alt} />
                    }
                </div>
            </footer>
        </>
    )
}

export default Container;