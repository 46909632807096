import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";

import { useAuthContext } from "../AuthContext";
import { useAuthCheck } from "../AuthContext";
import { useCompetitionContext } from "../CompetitionContext";

import Loading from "./Loading";

function ValidateSlug() {

    const { compSlug } = useParams();
    const navigate = useNavigate();
    
    const { root } = useAuthContext();
    const { checkJWT } = useAuthCheck();
    const { updateCompetitionCats, updateCurrentCompIndex } = useCompetitionContext();

    const [slugValidated, setSlugValidated] = useState(false);
    const [competitionOver, setCompetitionOver] = useState(false);

    useEffect(() => {

        async function verifyRoute() {

            let mainCategory;
            let subCategories;

            try {
                
                const res = await fetch(root + '/wp/v2/categories?slug=comp-post');
                const json = await res.json();

                mainCategory = json[0];

            } catch (error) {
                console.error(error);
            }

            try {

                const res = await fetch(root + `/wp/v2/categories?parent=${mainCategory.id}&acf_format=standard`);
                const json = await res.json();

                subCategories = json;

            } catch (error) {
                console.error(error);
            }

            if (subCategories) {
                
                const found = subCategories.find(cat => cat.slug === compSlug);
                const index = subCategories.findIndex(cat => cat.slug === compSlug);

                updateCompetitionCats(mainCategory, subCategories);
                updateCurrentCompIndex(index);

                if (!found) {
                    navigate('/404');
                    return
                }

                checkCompetionDeadline(subCategories[index].acf.project)

                setCompetitionOver(checkCompetionDeadline(subCategories[index].acf.project));
                setSlugValidated(found);
            }

        }

        verifyRoute();

    }, [root, compSlug, navigate, updateCompetitionCats, updateCurrentCompIndex]);

    useEffect(() => {

        if (!slugValidated) return

        if (competitionOver) {
            navigate(`/${compSlug}/over`);
            return
        }

        checkJWT(compSlug);

    }, [checkJWT, slugValidated, compSlug, competitionOver, navigate]);

    function checkCompetionDeadline(deadline) {
        const current = new Date()

        const [day, month, year] = deadline.split(".");
        const deadlineDate = new Date(year, month - 1, day);

        return current > deadlineDate
    }

    return (
        <>
            {slugValidated 
            ? <Outlet/>
            : <Loading/>
            }
        </>
    )
}

export default ValidateSlug;