import React from 'react';
import { bool, func } from 'prop-types';

const RightNav = React.memo(({
  disabled,
  onClick,
}) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav btn-frwd"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <img src={`${process.env.PUBLIC_URL}/assets/arrow.svg`} alt="Right Navigation"></img>
    </button>
  );
});

RightNav.displayName = 'RightNav';

RightNav.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
};


export default RightNav;