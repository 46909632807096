import React, { useContext, useState, useCallback } from 'react';

const CompetitionContext = React.createContext();

export function useCompetitionContext() {
    return useContext(CompetitionContext);
}

export function CompetitionProvider({children}) {

    const [ competitionCats, setCompetitionCats ] = useState({
        main: {},
        sub: {}
    });
    const [ currentCompIndex, setCurrentCompIndex ] = useState(null);

    const updateCompetitionCats = useCallback((main, sub) => {
        setCompetitionCats({
            main: main,
            sub: sub
        });
    }, []);

    const updateCurrentCompIndex = useCallback((num) => {
        setCurrentCompIndex(num);
    }, []);

    return (
        <CompetitionContext.Provider value={{ competitionCats, updateCompetitionCats, currentCompIndex, updateCurrentCompIndex}}>
            {children}
        </CompetitionContext.Provider>
    )
}