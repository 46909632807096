import React from 'react';
import { useNavigate } from "react-router-dom";

import '../scss/NotFound.scss';

function NotFound() {

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="container-txt">
                <p>Ups, bist du vom Weg abgekommen?</p>
                <button type="button" onClick={() => navigate('/')}>Zurück zur Übersicht</button>
            </div>
            <div className="container-img">
                <img src={`${process.env.PUBLIC_URL}/assets/NotFound_Ic.svg`} alt=""></img>
            </div>
        </div>
    )
}

export default NotFound;