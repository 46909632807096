import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../AuthContext';

import Loading from './Loading';

import '../scss/CompetitionSelection.scss';

function CompetitionSelection() {

    const { root } = useAuthContext();

    const [ competitions, setCompetitions ] = useState([]);

    useEffect(() => {

        async function fetchCompetitions() {
            
            let mainCategory;
            let subCategories;

            try {
                
                const res = await fetch(root + '/wp/v2/categories?slug=comp-post');
                const json = await res.json();

                mainCategory = json[0];

            } catch (error) {
                console.error(error);
            }

            try {

                const res = await fetch(root + `/wp/v2/categories?parent=${mainCategory.id}`);
                const json = await res.json();

                subCategories = json;

                setCompetitions(subCategories);

            } catch (error) {
                console.error(error);
            }

        }

        fetchCompetitions();

    }, [root])

    if (competitions.length === 0) {
        return <Loading />
    } else {
        return (
            <main className="container">
                <div className="container-content">
                    <h1>Wettbewerb Auswahl</h1>
                    <ul>
                        {competitions.map((comp, index) => {
                            return (
                                <Link to={`/${comp.slug}`} key={index}>
                                    {comp.name}
                                </Link>
                            )
                        })}
                    </ul>
                </div>
            </main>
        )
    }

}

export default CompetitionSelection;