import React from 'react';
import { Link } from 'react-router-dom';

import '../scss/ServerError.scss';

function ServerError() {

    return (
        <div className="error">

            <div className="error-container">

                <div className="error-container-img">
                    <img src={`${process.env.PUBLIC_URL}/assets/NotFound_Ic.svg`} alt=""></img>
                </div>

                <h2>Server Error</h2>
                <p>Versuchen Sie es zu einem späterem Zeitpunk noch ein Mal</p>
                <Link to="/">Home Page</Link>

            </div>

        </div>
    )
    
}

export default ServerError;