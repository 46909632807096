import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthCheck, useAuthContext } from '../AuthContext';
import { useCompetitionContext } from '../CompetitionContext';
import Loading from './Loading';

import '../scss/Login.scss';

function Login() {

  const navigate = useNavigate()
  const { compSlug } = useParams();

  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState(false);

  const updateUser = useAuthCheck().updateUser;
  const getCookie = useAuthCheck().getCookie;

  const root = useAuthContext().root;
  const loading = useAuthContext().loading;
  const userRef = useRef(useAuthContext().user);
  const user = useAuthContext().user;

  const { competitionCats, currentCompIndex } = useCompetitionContext();

  const handleChange = (e) => {

    setLoginData(prev => {
      return (
        { ...prev, [e.target.name]: e.target.value }
      )
    })
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    let formData = new FormData();
    formData.append('username', loginData.username);
    formData.append('password', loginData.password);

    const res = await fetch(root + '/jwt-auth/v1/token', {
      method: 'POST',
      'Content-Type': 'application/json',
      body: formData
    })

    if (res.status !== 200) {
      document.querySelector('.login-container__form--warn').style.opacity = "1";
      setError(true);
      return
    }

    const json = await res.json();

    document.cookie = `token=${json.token}; path=/;`;

    updateUser({
      id: json.user_id,
      name: json.user_display_name
    })

  }

  useEffect(() => {
    if (userRef.current === user) return

    async function handleLoginAttempt() {

      try {

        const res = await fetch(root + '/wp/v2/users/me?acf_format=standard', {
          headers: {
            authorization: "Bearer " + getCookie('token'),
            "Content-Type": "application/json"
          }
        })

        const json = await res.json();

        //Check if user has access to this competition

        if (competitionCats.sub[currentCompIndex].slug !== json.acf.competition.slug) { 
          document.cookie = `token=; path=/;`;
          navigate('/', { replace: true });
          return
        }

        json.acf.first_time_login
          ? navigate(`/${compSlug}/welcome`, {replace: true})
          : navigate(`/${compSlug}/profile`, {replace: true});

      } catch (e) {

        console.log(e)
        navigate('/error', { replace: true });

      }
    }

    handleLoginAttempt();

  }, [user, root, getCookie, navigate, compSlug, currentCompIndex, competitionCats])


  if (loading) {

    return (
      <Loading />
    )

  } else {

    return (
      <div className="login">

        <div className="login-container">

          <h1>Login</h1>

          <form className="login-container__form" onSubmit={handleSubmit}>
            <input className={error ? 'warn-border' : ''} type="text" autoComplete="off" name="username" placeholder="Benutzername" onChange={handleChange} required></input>
            <input className={error ? 'warn-border' : ''} type="password" autoComplete="off" name="password" placeholder="Passwort" onChange={handleChange} required></input>
            <a href={`${process.env.REACT_APP_BACKEND_URL}/wp-login.php?action=lostpassword`}>Passwort vergessen?</a>
            <p className="login-container__form--warn">Falscher Benutzername oder Passwort</p>
            <button type="submit">Anmelden</button>
          </form>

          <div className="login-container__logo">
            <img src={competitionCats.sub[currentCompIndex].acf.competition_login_icon.url} alt={competitionCats.sub[currentCompIndex].acf.competition_login_icon.alt}>
            </img>
          </div>

        </div>

      </div>
    )
  }
}

export default Login;