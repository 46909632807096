import React from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import parse from 'html-react-parser';

import { useAuthContext, useAuthCheck } from '../AuthContext';
import { useCompetitionContext } from '../CompetitionContext';
import Loading from './Loading';

import '../scss/Welcome.scss';

function Welcome() {
    
    const navigate = useNavigate();
    const location = useLocation();
    const { compSlug } = useParams();

    const loading = useAuthContext().loading;
    const root = useAuthContext().root;
    const user = useAuthContext().user;
    const { competitionCats, currentCompIndex } = useCompetitionContext();

    const getCookie = useAuthCheck().getCookie;

    async function handleFirstTimeLogin() {
        
        try {

            await fetch(root +'/wp/v2/users/me', {
                method: 'POST',
                headers: {
                  authorization: "Bearer " + getCookie('token'),
                  "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    acf: {
                        first_time_login: false
                    }
                })
            })

        } catch(e) {
            console.log(e)
            if (location.pathname !== '/error') {
                navigate('/error', {replace: true});
            }
        }

        try {

            const currentCompetitionObj = competitionCats.sub.find(cat => cat.slug === compSlug);

            const res = await fetch(root + '/wp/v2/posts', {
                method: 'POST',
                headers: {
                    authorization: "Bearer " + getCookie('token'),
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    title: user.name,
                    status: 'publish',
                    categories: [ competitionCats.main.id, currentCompetitionObj.id]
                })
            })

            if (res.status === 201) {
                navigate(`/${compSlug}/profile`, {replace: true});
            }

        } catch(e) {
            console.log(e)
            navigate('/error', {replace: true});
        }

    }

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <div className="welcome">
                <div className="welcome-container">
                    <h1>Willkommen!</h1>
                    <div className="welcome-container__txt" >
                        {
                            parse(competitionCats.sub[currentCompIndex].acf.info_text)
                        }
                        <button onClick={handleFirstTimeLogin}>Weiter zum Profil</button>
                    </div>
                    <div className="welcome-container__logo">
                        <div>
                            <img src={competitionCats.sub[currentCompIndex].acf.competition_welcome_icon.url} alt={competitionCats.sub[currentCompIndex].acf.competition_login_icon.alt} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome;